<template>

    <el-alert
        v-if="!getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)"
        :title="$t('noCurrentSchoolyearWarning')"
        type="warning"
        class="mb-5"/>

    <el-row :gutter="40" class="panel-group mb-10" v-if="getSchoolData(SchoolData.NAME.NAME)">
        <el-col :xs="48" :sm="48" :lg="24" class="card-panel-col">
            <el-card class="card-panel" shadow="hover">
                <template #header>
                    <div class="card-header">
                        <b style="font-size: 21px">{{ getSchoolData(SchoolData.NAME.NAME) }}</b>
                    </div>
                </template>
                <div>
                    <el-descriptions
                        class="margin-top"
                        :column="3"
                        border>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.PHONE.NAME)">
                            <template #label>
                                <el-icon><Phone/></el-icon>
                                {{ $t('phone') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.PHONE.NAME) }}</small>
                        </el-descriptions-item>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.EMAIL.NAME)">
                            <template #label>
                                <el-icon><Message/></el-icon>
                                {{ $t('email') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.EMAIL.NAME) }}</small>
                        </el-descriptions-item>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.ADDRESS.NAME)">
                            <template #label>
                                <el-icon><Location/></el-icon>
                                {{ $t('address') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.ADDRESS.NAME) }}</small>
                        </el-descriptions-item>

                    </el-descriptions>
                </div>
            </el-card>
        </el-col>
    </el-row>

</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { Settings, SchoolData, Routes } from '@/utils/enums'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Dashboard',
    mixins: [accessManager],
    data() {
        return {
            SchoolData,
            Settings,
            Routes
        }
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData,
            schoolyears: state => state.schoolyears.schoolyears
        }),
        ...mapGetters({
            getSettingValue: 'settings/getSettingValue',
            getSchoolData: 'schooldata/getSchoolDataValue'
        })
    },
    methods: {

    }
}
</script>

<style scoped>

    .card-panel {
        cursor: pointer;
        font-size: 16px;
        color: #666;
        background: #fff;
    }

    .card-panel-item{
        background: whitesmoke;
    }

    .card-panel-description{
        padding-top: 5px;
        margin-top: 10px;
    }

    .card-panel-num {
        float: right;
        font-weight: bold;
        font-size: 21px;
    }

    .card-header, .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-panel-text{
        font-size: 14px;
    }

</style>